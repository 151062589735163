import React from "react";
import { connect } from "react-redux";
import { getOr } from "lodash/fp";
import { PropTypes } from "prop-types";
import Select from "../../../input/selectors/select";
import FormBox from "../../../presentational/page/formBox";
import { enumsPath } from "../../../../constants/constants";

const CategoryBox = ({ enums }) => {
  let articleCategories = getOr([], "article_categories", enums);

  return (
    <FormBox title="Category">
      <div className="box-body">
        <div className="form-group">
          <Select
            data={articleCategories}
            placeholder="Category"
            name="formData.category"
            idKey="id"
            displayKey="name"
          />
        </div>
      </div>
    </FormBox>
  );
};

CategoryBox.propTypes = {
  category: PropTypes.object,
  dispatch: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    enums: getOr(null, `${enumsPath}`, state),
  };
};

export default connect(mapStateToProps)(CategoryBox);
