import React from "react";
import { PropTypes } from "prop-types";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import GeneralInfoBox from "../shared/generalInfoBox";
import TagsBox from "../shared/tagsBox";
import ContentBox from "../shared/contentBox";
import articleDataManipulation from "../../../../lib/dataManipulation/article/articleDataManipulation";
import { handleAddFormSubmit, redirectTo } from "../../../../lib/functions/componentFunctions";
import ContentComponentSelector from "../../../input/selectors/contentComponentSelector";
import { SET_SIDEBAR_ASK_FOR_CONFIRMATION } from "../../../../redux/reducers/general/actionTypes";
import MetaTagsBox from "../shared/metaTagsBox";
import { articleComponents } from "../../../../constants/article";
import { useResetPageState } from "../../../../lib/functions/customHooks";
import CategoryBox from "../shared/categoryBox";

const AddArticle = ({ dispatch }) => {
  useResetPageState();
  const routerHistory = useHistory();
  dispatch({ type: SET_SIDEBAR_ASK_FOR_CONFIRMATION, payload: true });

  return (
    <React.Fragment>
      <section className="content-header">
        <h1>Add Article</h1>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-md-8">
            <GeneralInfoBox />
            <div className="row">
              <div className="col-md-12">
                <ContentBox />
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <TagsBox />
          </div>
          <div className="col-md-4">
            <MetaTagsBox />
          </div>
          <div className="col-md-4">
            <CategoryBox />
          </div>
        </div>
        <div style={{ height: "100px" }}></div>
        <ContentComponentSelector
          onSubmit={async () => {
            const submitSuccessfull = await handleAddFormSubmit(false, articleDataManipulation, "article");
            if (submitSuccessfull) {
              redirectTo(routerHistory, "/list/article");
            }
          }}
          availableComponents={articleComponents}
          showPreviewButton={false}
        />
      </section>
    </React.Fragment>
  );
};

AddArticle.propTypes = {
  dispatch: PropTypes.func,
};

export default connect()(AddArticle);
