import moment from "moment";
import { getOr } from "lodash/fp";
import { parseImage } from "../../functions/common";
import commonDataManipulation from "../common/commonDataManipulation";

const articleDataManipulation = function articleDataManipulation() {
  const getOutwardArticleItem = data => {
    let parsedData;

    if (data) {
      parsedData = {
        title: data.title || null,
        lead: data.leadText || null,
        category: data.category ? data.category : null,
        author: {
          uuid: data.author && data.author.uuid ? data.author.uuid : null,
        },
        start: data.startDate ? moment(data.startDate).format("YYYY-MM-DD") + "T00:00:00Z" : null,
        end: data.endDate ? moment(data.endDate).format("YYYY-MM-DD") + "T00:00:00Z" : null,
        status: data.status && data.status.name ? data.status.name.toLowerCase() : null,
        tags: data && data.tagData && data.tagData.tags ? outwardArticleTags(data.tagData.tags) : null,
        content: data && data.content ? commonDataManipulation().content.outward(data.content) : null,
        thumbnail_image: data.thumbnail ? { ...parseImage(data.thumbnail) } : null,
        cover_video: data.cover_video || null,
        cover_image: data.cover ? { ...parseImage(data.cover) } : null,
        metadata: {
          title: data.metaTagTitle ? data.metaTagTitle : null,
          description: data.metaTagDescription ? data.metaTagDescription : null,
          image: data.metaTagImage ? { ...parseImage(data.metaTagImage) } : null,
        },
        featured: data.featured ? true : false,
        featured_learn: data.featured_learn ? true : false,
        similar_articles: data.similarArticles || [],
      };
    }

    return parsedData;
  };

  const getInwardEditArticleDatatable = data => {
    let parsedData = [];

    if (data && data.length && Array.isArray(data)) {
      data.forEach(item => {
        parsedData.push([
          item.title ? item.title : "",
          item.author && item.author.name ? item.author.name : "",
          item.start ? moment(item.start).format("YYYY-MM-DD") : "",
          item.end ? moment(item.end).format("YYYY-MM-DD") : "",
          item.status,
          item.tags,
          { status: item.status, slug: item.slug, category_slug: item.category ? item.category.slug : "" },
          item.views ? item.views : "Unknown",
          item.id,
        ]);
      });
    }

    return parsedData;
  };

  const getInwardEditArticleItemInitialValues = data => {
    const title = data.title ? data.title : "";
    const author =
      data.author && data.author.uuid && data.author.name
        ? {
            uuid: data.author.uuid,
            name: data.author.name,
            surname: data.author.surname,
            image: data.author.image,
            description: data.author.description,
            role: getOr("", "author.role", data),
          }
        : null;
    const category = data.category ? data.category : "";
    const status = data.status ? commonDataManipulation().contentStatus.inward(data.status) : {};
    const startDate = data.start ? moment(data.start).format("YYYY-MM-DD") : "";
    const endDate = data.end ? moment(data.end).format("YYYY-MM-DD") : "";
    const thumbnail = data.thumbnail_image && data.thumbnail_image.url ? data.thumbnail_image.url : null;
    const cover = data.cover_image && data.cover_image.url ? data.cover_image.url : null;
    const leadText = data.lead ? data.lead : "";
    const tags = data.tags ? inwardArticleItemTags(data.tags) : null;
    const content = data.content ? commonDataManipulation().contentItem.inward(data.content) : null;
    const similarArticles = data.similar_articles ? data.similar_articles : [];

    return {
      featured: getOr(false, "featured", data),
      featured_learn: getOr(false, "featured_learn", data),
      title: title,
      author: author,
      category: category,
      status: status,
      startDate: startDate,
      endDate: endDate,
      thumbnail: thumbnail,
      cover_video: getOr("", "cover_video", data),
      cover: cover,
      leadText: leadText,
      tagData: { tags: tags },
      content: content,
      metaTagTitle: getOr("", "metadata.title", data),
      metaTagDescription: getOr("", "metadata.description", data),
      metaTagImage: getOr("", "metadata.image.url", data),
      similarArticles,
      slug: getOr("", "slug", data),
    };
  };

  return {
    outward: getOutwardArticleItem,
    inwardDatatable: getInwardEditArticleDatatable,
    inwardItem: getInwardEditArticleItemInitialValues,
  };
};

export default articleDataManipulation();

const inwardArticleItemTags = data => {
  let tags = [];

  if (data && data.length > 0) {
    data.forEach(tag => {
      const id = tag.id ? tag.id : null;
      const page = tag.page ? tag.page : null;
      const section = tag.section ? tag.section : null;
      const title = (page ? page : "") + " " + (section ? section : "");
      const text = tag.text ? tag.text : "";
      tags.push({ id: id, page: page, section: section, text: text, title: title });
    });
  }

  return tags;
};

const outwardArticleTags = data => {
  let tags = [];

  if (data && data.length) {
    data.forEach(tag => {
      tags.push({
        id: tag.id ? tag.id.toString() : null,
        page: tag.page ? tag.page.toLowerCase() : null,
        section: tag.section ? tag.section.toLowerCase() : null,
        text: tag.text ? tag.text : null,
      });
    });
  }

  return tags;
};
